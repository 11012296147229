import { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import TweenMax from 'gsap';
import PropTypes from 'prop-types';
import { AppContext } from '../../context/AppContext';
import { handleHoverCursor, getWindow } from '../../utils/app';

const CursorWrapper = styled.div`
  /* CUSTOM CURSOR */
  transition: opacity 0.5s;
  opacity: 0;
  &.showCursor {
    opacity: 1;
  }
  &.cursor {
    pointer-events: none;
    .cursor__ball {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 900;
      .cursor__ball--small {
        z-index: 1000;
      }
    }
  }
`;

export default function Cursor() {
  const { mouseY, setMouseY } = useContext(AppContext);
  const { mouseX, setMouseX } = useContext(AppContext);
  const { showMouse, setShowMouse } = useContext(AppContext);

  useEffect(() => {
    const bigBall = document.querySelector('.cursor__ball--big');

    const setMousePosition = (mouseX, mouseY) => {
      TweenMax.to(bigBall, 0.4, {
        x: mouseX - 28,
        y: mouseY - 28,
      });
    };

    // Init the cursor
    setMousePosition(mouseX, mouseY);

    // Move the cursor
    function onMouseMove(e) {
      setMousePosition(e.pageX, e.pageY);

      if (!showMouse) {
        setShowMouse(true);
      }
    }

    // Click the cursor
    // function onMouseenter(e) {
    //   setMouseX(e.pageX);
    //   setMouseY(e.pageY);
    // }

    // Listeners
    getWindow().addEventListener('mousemove', onMouseMove, false);

    //  ---Handle Hover Cursor---
    handleHoverCursor(true);

    return () => {
      getWindow().removeEventListener('mousemove', onMouseMove, false);
      handleHoverCursor(false);
    };
  }, []);

  return (
    <CursorWrapper className={`${showMouse ? 'showCursor' : ''} cursor`}>
      <div className="cursor__ball cursor__ball--big ">
        <svg
          fill="none"
          height="56"
          viewBox="0 0 56 56"
          width="56"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="27.5" stroke="#D0D0D0" />
        </svg>
      </div>
    </CursorWrapper>
  );
}

Cursor.propTypes = {
  className: PropTypes.string,
};

Cursor.defaultProps = {
  className: '',
};
