import { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { PolygonIcon } from '../Shape/PolygonIcon';
import { SocialIcons } from '../SocialIcons';
import { GradientText } from '../Text';
import { P2 } from '../Text/P2';
import {
  bottomUp200,
  opacityUp,
  bottomDown200,
  bodyBottomUp,
  bodyBottomDown,
  opacityDown,
} from '../Theme/Animation';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';

const NavLink = props => {
  const { pathname } = useLocation();
  const { path } = props || {};

  return (
    <button
      {...props}
      className={`nav__link hoverable ${
        pathname.replace(/^.+(\/)$/g, pathname.slice(0, -1)) === path
          ? 'active'
          : ''
      }`}
    />
  );
};

const NavBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  overflow-y: scroll;
  height: 1px;
  z-index: 11;

  background-color: ${colors.backgroundDark};
  transition: all 1s ${props => (props.open ? '' : '0.5s')}
    cubic-bezier(0.25, 0.46, 0.45, 0.94);

  .nav-bar__cover {
    display: flex;
    justify-content: center;
  }

  .header-line {
    position: absolute;
    height: 100%;
    width: 0.1rem;
    background-color: #2c2c2c;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .nav-bar__links,
  .nav-bar__locations {
    display: flex;
    flex-basis: 50%;
    align-items: center;
    position: relative;
    opacity: 0;
    padding-top: 6rem;
  }

  .nav-bar__links {
    max-width: 56rem;
    ul {
      text-align: left;

      li:not(:last-child) {
        margin-bottom: 3.1rem;
      }

      li {
        list-style-type: none;

        button {
          text-decoration: none;
          display: inline-flex;
          align-items: center;
          transition: transform 0.5s;

          figure {
            display: none;
          }

          &:hover {
            span {
              transform: translateX(2.4rem);
              text-shadow: none;
              background-image: ${colors.gradientOrange};
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              transition: transform 1s, background-image 1s, background-clip 1s;
            }
            figure {
              display: inline-block;
            }
          }
        }

        button.active {
          span {
            color: ${colors.textNavColor};
            text-shadow: none;
          }
        }
      }
    }
  }

  .location__social-icons {
    margin-top: 2rem;
    margin-bottom: 2.4rem;
  }

  .nav-bar__locations {
    color: ${colors.textNavColor};
    text-align: left;
    max-width: 49rem;
    padding-left: 12.8rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .mail__to {
      font-size: 2.4rem;
      line-height: 119.16%;
      letter-spacing: normal;
      color: ${colors.textNavColor};
      text-decoration: none;
      &:hover {
        background-image: ${colors.gradientOrange};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .nav-bar__location {
      margin-bottom: 3rem;
      max-width: 40rem;

      p {
        margin-bottom: 1rem;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 148%;
        letter-spacing: 0.04em;
      }

      .location__city {
        font-weight: normal;
      }
    }
  }

  .open__nav {
    animation: opacityDown 1s forwards;
  }
  &.open {
    height: 100%;
    .open__nav {
      opacity: 0;
      animation: opacityUp 1s 0.5s forwards;
    }
  }

  @media (min-width: 1441px) {
    .nav-bar__links {
      ul li {
        button {
          span {
            font-size: 13rem;
          }
        }
      }
    }
  }

  ${media.mobileWide`
  .nav-bar__cover {
    flex-direction: column;
    justify-content: unset;
  }
    
    &.open {
      padding: 0 0 6rem;
    }

    .header-line {
      display: none;
    }
    .nav-bar__links {
      display: block;
      padding: 7.2rem 2.4rem 8rem;
      ul {
        li:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }

    .nav-bar__locations {
      padding: 0 2.4rem;
      justify-content: unset;

      .location__city {
        letter-spacing: 0.04em;
      }

      .mail__to {
        font-size: 2.0rem;
        margin-bottom: 6rem;
      }
    }
  `}

  /* animation */
  ${bottomUp200}
  ${opacityUp}
  ${bottomDown200}
  ${bodyBottomUp}
  ${bodyBottomDown}
  ${opacityDown}
`;

const navItems = [
  { title: 'Home', path: '/' },
  { title: 'Careers', path: '/careers' },
  { title: 'Blog', path: 'https://medium.com/leclevietnam' },
];

const locations = [
  {
    city: 'Ho Chi Minh',
    address:
      '81 Cách Mạng Tháng Tám, Phường Bến Thành, Quận 1, Hồ Chí Minh 700000',
    phone: '(+84) 28 6295 8827',
  },
  {
    city: 'South Korea',
    address: '16F, 714, Yeongdong-daero, Gangnam-gu, Seoul, Republic of Korea',
    phone: '',
  },
  {
    city: 'Singapore',
    address: '21 Heng Mui Keng Terrace, Singapore 119613',
    phone: '',
  },
  {
    city: 'United States',
    address: '815-A Brazos ST. #435 Austin, TX78701',
    phone: '',
  },
];

export const NavBar = ({ toggleMenu, open, layoutRef, children }) => {
  const linkRef = useRef(null);
  const locationRef = useRef(null);
  const containerRef = useRef(null);

  const usePrevious = value => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  };

  const previousOpen = usePrevious(open);

  useEffect(() => {
    let animation;

    if (open) {
      // STEP: 1
      containerRef.current.scrollTop = 0;
      // STEP: 2
      layoutRef.current.classList.add('layout__overlay-open'); // Add class to Layout.jsx
      layoutRef.current.style.animation = 'bodyBottomUp 1s';
      // STEP: 3
      animation = 'bottomUp200 1s 0.5s, opacityUp 0.25s 0.5s forwards';
      linkRef.current.style.animation = animation;
      locationRef.current.style.animation = animation;
      // STEP: 4
      setTimeout(() => {
        layoutRef.current &&
          layoutRef.current.classList.remove('layout__overlay-open');
      }, 1000);
    } else if (previousOpen !== open && previousOpen !== undefined) {
      // When close nav menu
      // STEP: 1
      animation = 'bottomDown200 1s';
      linkRef.current.style.animation = animation;
      locationRef.current.style.animation = animation;
      // STEP: 2
      layoutRef.current.classList.add('layout__overlay-close'); // Add class to Layout.jsx
      layoutRef.current.style.animation = 'bodyBottomDown 1s 0.5s';
      // STEP: 3
      setTimeout(() => {
        layoutRef.current &&
          layoutRef.current.classList.remove('layout__overlay-close');
      }, 2000);
    }
  }, [open]);

  return (
    <NavBarWrapper
      ref={containerRef}
      className={open ? 'open' : ''}
      open={open}
    >
      {children}
      <div className="nav-bar__cover">
        <nav ref={linkRef} className="nav-bar__links">
          <ul>
            {navItems.map(({ title, path }) => (
              <li key={title}>
                <NavLink
                  path={path}
                  onClick={() => {
                    toggleMenu();
                    navigate(path);
                  }}
                >
                  <PolygonIcon color={colors.organge} />
                  <GradientText>{title}</GradientText>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="header-line" />
        <div ref={locationRef} className="nav-bar__locations">
          {locations.map(({ city, address, phone }) => (
            <div key={city} className="nav-bar__location">
              <P2 className="location__city">{city}</P2>
              <P2>{address}</P2>
              <P2>{phone}</P2>
            </div>
          ))}
          <SocialIcons className="location__social-icons" />
          <a className="mail__to hoverable" href="mailto: career@lecle.vn">
            career@lecle.vn
          </a>
        </div>
      </div>
    </NavBarWrapper>
  );
};

NavBar.propTypes = {
  toggleMenu: PropTypes.func,
  open: PropTypes.bool,
  layoutRef: PropTypes.any.isRequired,
};
