import { css } from '@emotion/core';

export const changeText = css`
  @keyframes changeText {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    20% {
      opacity: 0.01;
      transform: translateY(0);
    }
    35% {
      opacity: 1;
      transform: translateY(0);
    }
    45% {
      opacity: 1;
      transform: translateY(0);
    }
    55% {
      opacity: 0;
      transform: translateY(-80%);
    }
    100% {
      opacity: 0;
      transform: translateY(-80%);
    }
  }
`;
export const fadeOut = css`
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
      z-index: 15;
      height: 100vh;
    }
    100% {
      opacity: 0;
      z-index: -1;
      transform: translateY(-100%);
      height: 0;
    }
  }
`;
export const bottomUp50 = css`
  @keyframes bottomUp50 {
    0% {
      bottom: -50px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;
export const bottomUp200 = css`
  @keyframes bottomUp200 {
    0% {
      bottom: -200px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;
export const bottomDown200 = css`
  @keyframes bottomDown200 {
    0% {
      bottom: 0;
      opacity: 1;
    }
    100% {
      bottom: -200px;
      opacity: 0;
    }
  }
`;
export const opacityUp = css`
  @keyframes opacityUp {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const opacityDown = css`
  @keyframes opacityDown {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
export const bodyBottomUp = css`
  @keyframes bodyBottomUp {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 160px;
    }
  }
`;
export const bodyBottomDown = css`
  @keyframes bodyBottomDown {
    0% {
      bottom: 160px;
      position: relative;
    }
    100% {
      bottom: 0;
      position: relative;
    }
  }
`;
export const opacityUp4 = css`
  @keyframes opacityUp4 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }
`;
export const opacityDown0 = css`
  @keyframes opacityDown0 {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 0;
    }
  }
`;
export const bottomUp30 = css`
  @keyframes bottomUp30 {
    0% {
      transform: translateY(0);
      opacity: 0.7;
    }
    100% {
      transform: translateY(-30%);
      opacity: 1;
    }
  }
`;
export const toLeftAuto = css`
  @keyframes toLeftAuto {
    0% {
      transform: translateX(20%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
export const bottomUpAuto = css`
  @keyframes bottomUpAuto {
    0% {
      transform: translateY(20%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const bounce = css`
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;
