import { css } from '@emotion/core';

export const sizes = {
  mobile: 320,
  mobileWide: 768,
  tabletWide: 1024,
  desktop: 1146,
  desktop1: 1159,
  desktopWide: 1279,
  desktopGiant: 1439,
};
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const pxSize = sizes[label];

  accumulator[label] = (...args) => css`
    @media (max-width: ${pxSize}px) {
      ${css(...args)};
    }
  `;

  return accumulator;
}, {});
