import { css } from '@emotion/core';
import { colors } from './Colors';

export const resetButton = css`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
export const alignMiddle = css`
  display: grid;
  place-items: center;
`;

export const Text = css`
  color: ${colors.textNavColor};
  &:hover {
      background-image: ${colors.gradientOrange};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:focus,
    &:active {
      color: ${colors.textNavColor};
      background-image: none;
      -webkit-text-fill-color: initial;
      -webkit-background-clip: initial;
    }
`;
