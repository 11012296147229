import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import facebookIcon from '../../../static/images/facebook.svg';
import mediumIcon from '../../../static/images/medium.svg';
import { colors } from '../Theme/Colors';

export const SocialIconsWrapper = styled.div`
  .icon-wrapper {
    margin-right: 2.2rem;
    display: inline-block;
    .img {
      display: inline-block;
      width: ${props => props.widthSize};
      height: ${props => props.heightSize};
      mask-size: 100% 100%;
      background-color: ${colors.footerColor};
      transition: all 0.7s;
      cursor: pointer;
      &.facebook {
        mask-image: url(${facebookIcon});
      }
      &.medium {
        mask-image: url(${mediumIcon});
      }
    }
    &:hover {
      .img {
        transform: translateY(-1rem);
        background-image: ${colors.gradientOrange};
      }
    }
  }
`;
export const SocialIcons = ({ className, widthSize, heightSize }) => (
  <SocialIconsWrapper
    className={className}
    heightSize={heightSize}
    widthSize={widthSize}
  >
    <div className="icon-wrapper">
      <a
        className="img facebook hoverable"
        href="https://www.facebook.com/LecleVietnam"
        target="_blank"
      />
    </div>
    <div className="icon-wrapper">
      <a
        className="img medium hoverable"
        href="https://medium.com/leclevietnam"
        target="_blank"
      />
    </div>
  </SocialIconsWrapper>
);

SocialIcons.propTypes = {
  className: PropTypes.string,
  widthSize: PropTypes.string,
  heightSize: PropTypes.string,
};

SocialIcons.defaultProps = {
  className: ' ',
  widthSize: '4.8rem',
  heightSize: '4.8rem',
};
