import { useEffect } from 'react';
import styled from '@emotion/styled';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isDesktopScreen, HAS_ACCESS_TO } from '../../utils/constants';
import storage from '../../utils/storage';
import { ChangeText } from '../ChangeText';
import { fadeOut, bottomUp200 } from '../Theme/Animation';
import { media } from '../Theme/Media';
import { colors } from '../Theme/Colors';

const CooperationWrapper = styled.aside`
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: 15;

  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${colors.backgroundLight};

  display: flex;
  align-items: center;
  padding-left: 5rem;

  animation: fadeOut 1.5s 2s;
  animation-fill-mode: forwards;

  /* animation */
  ${fadeOut}
  ${bottomUp200}

  ${media.mobileWide`
    padding-left: 2rem;
  `}
`;

export const Cooperation = () => {
  useEffect(() => {
      // Handle Welcome Section
      document.body.classList.add("prevent-scroll");
      setTimeout(() => {
        if (isDesktopScreen) {
          window.scroll.update();
          ScrollTrigger.refresh();
        }
        storage.set(HAS_ACCESS_TO, true);
        document.body.classList.remove("prevent-scroll");
      }, 3500);
      // Handle Welcome Section
  }, []);

  return (
    <CooperationWrapper>
      <ChangeText />
    </CooperationWrapper>
  );
};
