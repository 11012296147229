import styled from '@emotion/styled';
import { changeText } from '../Theme/Animation';
import { media } from '../Theme/Media';

const ChangeTextWrapper = styled.div`
  position: relative;
  width: 100%;
  font-size: 55px;
  line-height: 42px;
  display: flex;
  span {
    margin-right: 2.4rem;
    /* font-size: 8rem;
    line-height: 9.192rem; */
    font-weight: 400;
  }

  .change-text__mask {
    /* position: absolute;
    display: inline-block;
    height: 100%;
    overflow-y: hidden; */
    height: 100%;
    width: 100%;
    ul {
      height: 100px;
      width: 100%;
      overflow: hidden;
      position: relative;
      li {
        opacity: 0;
        position: absolute;

        &:nth-of-type(1) {
          animation: changeText 2s 0s infinite ease-in-out;
        }

        &:nth-of-type(2) {
          animation: changeText 2s 0.5s infinite ease-in-out;
        }

        &:nth-of-type(3) {
          animation: changeText 2s 1s infinite ease-in-out;
        }

        &:nth-of-type(4) {
          animation: changeText 2s 1.5s infinite ease-in-out;
        }
      }
    }
  }

  ${media.mobileWide`
    flex-direction: column;
    font-size: 45px;
    line-height: 35px;
    ul {
      margin-top: 10px;
    }
    /* span {
      font-size: 50px;
    }
    .change-text__mask {
      ul {
        font-size: 5rem;
      }
    } */
  `}

  /* animation */
  ${changeText}
`;

export const ChangeText = () => (
  <ChangeTextWrapper>
    <span>LECLE. </span>
    <div className="change-text__mask">
      <ul>
        <li>Web Development</li>
        <li>App Development</li>
        <li>UX/UI Design</li>
        <li>IT Consulting</li>
      </ul>
    </div>
  </ChangeTextWrapper>
);
