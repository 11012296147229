import { css } from '@emotion/core';

export const Heading1 = css`
  font-style: italic;
  font-weight: bold;
  font-size: 10rem;
  line-height: 12rem;
`;
export const Heading2 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 6rem;
  line-height: 7rem;
  letter-spacing: 0.02em;
`;
export const BoldHeading2 = css`
  ${Heading2};
  font-weight: bold;
`;
export const Heading3 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 4.8rem;
  line-height: 5.7rem;
`;
export const Heading4 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  line-height: 4.8rem;
  letter-spacing: 0.04em;
`;
export const Heading5 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 3.7rem;
`;
export const BoldHeading5 = css`
  ${Heading5};
  font-weight: bold;
`;
export const Heading6 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.04em;
`;
export const BoldHeading6 = css`
  ${Heading6};
  font-weight: bold;
`;
export const Body1 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.9rem;
  letter-spacing: 0.04em;
`;
export const Body2 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
`;
export const BoldBody2 = css`
  ${Body2};
  font-weight: bold;
`;
export const Body3 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 126%;
  letter-spacing: 0.19em;
`;
export const Body4 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 126%;
  letter-spacing: 0.19em;
`;
