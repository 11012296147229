import { useEffect } from 'react';
// We are excluding this from loading at build time in gatsby-node.js
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';

const Scroll = callbacks => {
  useEffect(() => {
    let locomotiveScroll;

    gsap.registerPlugin(ScrollTrigger);
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector('#smooth-scroll'),
      smooth: true, // smooth scroll
      multiplier: 0.8,
      getSpeed: true,
      smoothMobile: false,
      getDirection: true,
      touchMultiplier: 2.5,
      lerp: 0.15,
      reloadOnContextChange: true,
      firefoxMultiplier: 500,
    });
    locomotiveScroll.on('scroll', ScrollTrigger.update);
    ScrollTrigger.scrollerProxy('#smooth-scroll', {
      scrollTop(value) {
        return arguments.length
          ? locomotiveScroll.scrollTo(value, 0, 0)
          : locomotiveScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          left: 0,
          top: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: document.querySelector('#smooth-scroll').style.transform
        ? 'transform'
        : 'fixed',
    });
    locomotiveScroll.update();
    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll;

    locomotiveScroll.on('scroll', func => {
      // Update `data-direction` with scroll direction.
      document.documentElement.setAttribute('data-direction', func.direction);
    });

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy();
    };
  }, [callbacks]);

  return null;
};

export default Scroll;
