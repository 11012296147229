import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';

const { eerie, eerie500 } = colors;

const TextWrapper = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 10rem;
  line-height: 148%;
  letter-spacing: 0.04em;
  color: ${({ color }) => color};
  text-shadow: -1px 1px 0 ${({ textShadow }) => textShadow},
    1px 1px 0 ${({ textShadow }) => textShadow},
    1px -1px 0 ${({ textShadow }) => textShadow},
    -1px -1px 0 ${({ textShadow }) => textShadow};
  font-family: Ubuntu, sans-serif;

  ${media.mobileWide`
    font-size: 6rem;
  `}
`;

export const GradientText = ({ children, color, textShadow }) => (
  <TextWrapper color={color} textShadow={textShadow}>
    {children}
  </TextWrapper>
);

GradientText.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  textShadow: PropTypes.string,
};

GradientText.defaultProps = {
  color: eerie,
  textShadow: eerie500,
};
