import { useRef } from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Cursor from '../../components/Layout/Cursor';
import { checkServer } from '../../utils/app';
import { isDesktopScreen, HAS_ACCESS_TO } from '../../utils/constants';
import storage from '../../utils/storage';
import { Cooperation } from '../Cooperation';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { opacityUp4, opacityDown0 } from '../Theme/Animation';
import GlobalStyle from '../Theme/GlobalStyle';
import Scroll from './Scroll';
import SEO from '../../components/seo';

const LayoutWrapper = styled.div`
  position: relative;
  &.layout__overlay-open {
    &:after {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      animation: opacityUp4 1s;
    }
  }

  &.layout__overlay-close {
    &:after {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      animation: opacityDown0 1s 0.5s;
    }
  }
  /* animation */
  ${opacityUp4}
  ${opacityDown0}
`;

export default function Layout({
  children,
  hasLoading,
  hasFooter,
  nextPage,
  lightHeader,
  className,
  location,
  modalContainer,
  seo,
}) {
  const layoutRef = useRef(null);
  const hasAccessTo = storage.get(HAS_ACCESS_TO);

  return (
    <LayoutWrapper ref={layoutRef} className={className}>
      <Global styles={GlobalStyle} />
      <SEO {...seo} />
      {isDesktopScreen && <Scroll callbacks={location} />}
      {!checkServer() && (
        <>
          {!hasAccessTo && hasLoading && <Cooperation />}
          <Header layoutRef={layoutRef} lightHeader={lightHeader} />
          <div className="smooth-scroll" id="smooth-scroll">
            <main>{children}</main>
            {hasFooter && <Footer nextPage={nextPage} />}
          </div>
          {modalContainer}
        </>
      )}
      {isDesktopScreen && <Cursor />}
    </LayoutWrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
  hasLoading: PropTypes.bool,
  hasFooter: PropTypes.bool,
  nextPage: PropTypes.string,
  className: PropTypes.string,
  location: PropTypes.object,
  modalContainer: PropTypes.node,
};

Layout.defaultProps = {
  hasLoading: false,
  hasFooter: true,
  className: '',
};
