import { useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { handleNextPageLink, getWindow } from '../../utils/app';
import { isFullPage } from '../../utils/constants';
import { SocialIcons } from '../SocialIcons';
import { P2 } from '../Text/P2';
import { P4 } from '../Text/P4';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';
import { Text } from '../Theme/Mixins';
import { Heading3, Heading5, Heading6 } from '../Theme/Typography';
import { RelatedCompanies } from './RelatedCompanies';

const FooterWrapper = styled.footer`
  /* padding: 0;
  position: relative; */
  height: ${isFullPage ? '100vh' : 'auto'};
  min-height: ${isFullPage ? 'unset' : '102.4rem'};

  text-align: center;
  color: ${colors.footerColor};
  background-color: ${colors.eerie};

  padding-top: 9rem;
  display: flex;
  flex-direction: column;
  gap: 7rem;
  justify-content: center;

  .footer__top {
    /* position: absolute; */
    width: 100%;
    /* bottom: 35%; */
    flex: 1;
    .mail__to {
      ${Heading3}
      ${Text}
      text-decoration: none;
    }
  }

  .footer__bottom {
    /* position: absolute; */
    width: 100%;
    /* bottom: 0; */
  }

  .footer__top,
  .footer__bottom {
    transition: all 1s 1s;
    opacity: ${isFullPage ? '0' : '1'};
    transform: ${isFullPage ? 'translateY(10rem)' : 'none'};
  }

  &.is-inview {
    .footer__top,
    .footer__bottom {
      opacity: 1;
      transform: translateY(0);
    }
  }

  p {
    margin-bottom: 2rem;
  }

  .footer__social-icons {
    .icon-wrapper {
      margin-right: 4rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer__social-icons, .footer__related-companies {
    margin-top: 7rem;
  }

  .footer__next-page {
    margin-bottom: 9rem;
    .footer__next-btn {
      ${Heading5}
      span {
        ${Text}
      }
      margin-bottom: 3.2rem;
    }
  }}

  ${media.tabletWide`
    padding: 10rem 0 6rem;
    min-height: initial;
    height: auto;

    .footer__top,
    .footer__bottom {
      position: initial;
      bottom: initial;
      opacity: 1;
      transform: none;
      transition: none;
    }

    .footer__social-icons {
      margin-top: 4rem;
      /* margin-bottom: 18.2rem; */
      .icon-wrapper {
        .img {
          width: 6rem;
          height: 6rem;
        }
      }
    }

    .footer__related-companies {
      margin-top: 4rem;
      margin-bottom: 10rem;
    }

    .footer__next-page {
      margin-bottom: 0;
    }
  `}

  ${media.mobileWide`
    .footer__top {
      .mail__to {
        ${Heading6}
      }
    }
    .footer__next-page {
      .footer__next-btn {
        ${Heading6}
      }
    }
  `}
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${colors.eerie};

  .active__bar {
    width: 0;
    height: 1rem;
    background-image: ${colors.gradientOrange};
  }

  ${media.tabletWide`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
  `}
`;

let progress = 0;
let progressReq;

export const Footer = ({ nextPage }) => {
  const progressBarRef = useRef(null);
  const [isScrollToBottom, setScrollToBottom] = useState(false);

  const handleCancelAnimationFrame = () => {
    progress = 0;
    cancelAnimationFrame(progressReq);
  };

  const animateProgress = () => {
    if (isScrollToBottom) {
      if (progress < 100) {
        progress += 0.6;
        progressReq = requestAnimationFrame(animateProgress);
      } else {
        cancelAnimationFrame(progressReq);
        handleNextPageLink(getWindow().location.pathname);
      }
    } else {
      handleCancelAnimationFrame();
    }

    progressBarRef.current &&
      (progressBarRef.current.style.width = progress + '%');
  };

  const handleFooterAnimation = hasScrollFooter => {
    if (hasScrollFooter) {
      setScrollToBottom(true);
    } else {
      setScrollToBottom(false);
    }
  };

  useEffect(() => {
    animateProgress();
  }, [isScrollToBottom]);

  return (
    <>
      <FooterWrapper className="footer" data-scroll>
        <div className="footer__top">
          <P2>Let&apos;s keep conversation at</P2>
          <a className="mail__to hoverable" href="mailto: career@lecle.vn">
            career@lecle.vn
          </a>

          <SocialIcons
            className="footer__social-icons"
            heightSize="8rem"
            widthSize="8rem"
          />
          <RelatedCompanies className="footer__related-companies" />
        </div>
        <div className="footer__bottom">
          <div className="footer__next-page">
            <P4>CLICK TO GO NEXT</P4>
            {nextPage && (
              <button
                className="hoverable footer__next-btn"
                onClick={() => handleFooterAnimation(true)}
              >
                <span>{nextPage}</span>
              </button>
            )}
          </div>
          <ProgressBar>
            <div ref={progressBarRef} className="active__bar" />
          </ProgressBar>
        </div>
      </FooterWrapper>
    </>
  );
};
