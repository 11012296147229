import styled from '@emotion/styled';
import { media } from '../Theme/Media';
import { Body2, Body3 } from '../Theme/Typography';

export const P2 = styled.p`
  ${Body2};

  ${media.mobileWide`
    ${Body3}
  `}
`;
