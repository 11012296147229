import styled from '@emotion/styled';
import devera from '../../../static/images/devera.png';
import studio_jin from '../../../static/images/studio-jin.png';
import { P2 } from '../Text/P2';
import { media } from '../Theme/Media';

const RelatedCompaniesWrapper = styled.div`
  .company_list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;

    img {
      height: 8rem;
    }
    .devera {
      height: 7rem;
    }

    ${media.tabletWide`
      gap: 4rem;

      img {
        height: 4rem;
      }
      .devera {
        height: 3.5rem;
      }
    `}
  }
`;

export const RelatedCompanies = ({ className }) => {
  const companies = [
    {
      key: 'devera',
      img: devera,
      path: 'https://devera.vn/',
      className: 'devera',
    },
    { key: 'studio-jin', img: studio_jin, path: 'https://www.studio-jin.net/' },
  ];

  return (
    <RelatedCompaniesWrapper className={className}>
      <P2>Related Companies</P2>

      <div className="company_list">
        {companies.map(company => (
          <a
            key={company.key}
            className="hoverable"
            href={company.path}
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt={company.key}
              className={company.className ?? ''}
              src={company.img}
            />
          </a>
        ))}
      </div>
    </RelatedCompaniesWrapper>
  );
};
