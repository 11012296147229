import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const PolygonIcon = ({ color, className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 14 16"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 1.01036C6.7141 0.742414 7.2859 0.742414 7.75 1.01036L12.6782 3.85566C13.1423 4.12361 13.4282 4.6188 13.4282 5.1547V10.8453C13.4282 11.3812 13.1423 11.8764 12.6782 12.1443L7.75 14.9896C7.2859 15.2576 6.7141 15.2576 6.25 14.9896L1.3218 12.1443C0.857695 11.8764 0.571797 11.3812 0.571797 10.8453V5.1547C0.571797 4.6188 0.857695 4.12361 1.3218 3.85566L6.25 1.01036Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

PolygonIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
