import { checkServer } from './app';

const set = (key, value) => {
  if (!key || checkServer()) return;
  sessionStorage.setItem(key, JSON.stringify(value));
};

const get = key => {
  if (!key || checkServer()) return;
  var item = sessionStorage.getItem(key);

  if (!item) {
    return;
  }

  if (item === 'undefined') return null;

  return JSON.parse(item);
};

const remove = key => {
  if (!key || checkServer()) return;
  sessionStorage.removeItem(key);
};

const clear = () => {
  if (checkServer()) return;
  sessionStorage.clear();
};

const storage = {
  get,
  set,
  remove,
  clear,
};

export default storage;
