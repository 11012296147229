import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import lecleText from '../../../static/images/lecleText.svg';
import logo_white from '../../../static/images/logo_white.svg';
import { getWindow, isDarkMode } from '../../utils/app';
import { PAGE_ROUTES, isDesktopScreen } from '../../utils/constants';
import { PolygonIcon } from '../Shape';
import { P3 } from '../Text/P3';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';
import { resetButton } from '../Theme/Mixins';
import { NavBar } from './NavBar';

const StyledP3 = styled(P3)`
  color: ${({ color }) => color};
  text-transform: uppercase;
  letter-spacing: normal;
  transition: all 1s 1s;
  font-family: Ubuntu, sans-serif;
`;

const HeaderWrapper = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
`;

const TopHeaderWrapper = styled.div`
  text-align: right;
  margin: 0;
  padding: 6.2rem 14.7rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .header__logo {
    border: none;
    background: none;
    opacity: 1;
    transition: opacity 1s;

    &.header__hide__logo {
      opacity: 0;
    }
    img.lecle-text {
      filter: ${({ lightHeader }) => (lightHeader ? 'invert(1)' : 'none')};
      z-index: 10;
      position: relative;
    }
  }

  .header__menu-control {
    ${resetButton};
    display: inline-flex;
    align-items: center;
    z-index: 10;
    padding: 1rem 1.6rem;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 2.4rem;

    &:hover {
      .menu-controll__icon {
        svg {
          path {
            fill: ${({ color }) =>
              color === colors.eerie900 ? colors.cultured : colors.eerie900};
            stroke: ${({ color }) => color};
          }
        }
      }
    }

    .menu-controll__icon {
      margin-left: 1rem;
      margin-right: 1px;
    }
  }

  ${media.mobileWide`
    padding: 3.2rem 0 0;
    .header__menu-control {
      position: absolute;
      right: 1.5rem;
    }

    img.lecle-text {
      visibility: hidden;
    }   
  `}
`;

const TopHeader = ({
  hiddenLogo,
  logoImg,
  onClick,
  color,
  menuButtonEl,
  textButton,
  backgroundColor,
  className,
  lightHeader,
}) => {

  return (
    <TopHeaderWrapper
      backgroundColor={backgroundColor}
      className={className}
      color={color}
    >
      <button
        className={`${
          hiddenLogo ? 'header__hide__logo' : ''
        } header__logo hoverable`}
        onClick={() => {
          navigate(PAGE_ROUTES.HomePage);
        }}
      >
        <img
          alt="lecle viet nam"
          className="lecle-text"
          src={isDarkMode || lightHeader ? logo_white : logoImg}
        />
      </button>
      <button
        ref={menuButtonEl}
        className="header__menu-control hoverable"
        type="button"
        onClick={onClick}
      >
        <StyledP3 color={color}>{textButton}</StyledP3>
        <PolygonIcon className="menu-controll__icon" color={color} />
      </button>
    </TopHeaderWrapper>
  );
};

export const Header = ({ lightHeader, layoutRef }) => {
  const [openNavBar, setOpenNavBar] = useState(false);
  const [hiddenLogo, setHiddenLogo] = useState(false);
  const menuButtonRef = React.createRef();

  useEffect(() => {
    if (isDesktopScreen && !openNavBar) {
      const handleScrollHeader = e => {
        if (e.scroll.y > 30) {
          setHiddenLogo(true);
        } else {
          setHiddenLogo(false);
        }
      };

      window.scroll.on('scroll', e => handleScrollHeader(e));
    }
  }, [openNavBar]);

  const offsetRight =
    getWindow().innerWidth -
    ((menuButtonRef.current &&
      menuButtonRef.current.getBoundingClientRect().right) ||
      0);

  const toggleMenu = () => {
    setOpenNavBar(!openNavBar);
  };

  return (
    <HeaderWrapper
      data-scroll-target="#smooth-scroll"
      lightHeader={lightHeader}
      offsetRight={offsetRight}
      open={openNavBar}
      data-scroll
      data-scroll-sticky
    >
      <TopHeader
        backgroundColor={colors.menuColor}
        className=""
        color={colors.polygonIcon}
        hiddenLogo={hiddenLogo}
        logoImg={lecleText}
        menuButtonEl={menuButtonRef}
        textButton="MENU"
        onClick={toggleMenu}
        lightHeader={lightHeader}
      />
      <NavBar layoutRef={layoutRef} open={openNavBar} toggleMenu={toggleMenu}>
        <TopHeader
          backgroundColor={colors.eerie}
          className="open__nav"
          color={colors.cultured}
          hiddenLogo={false}
          logoImg={logo_white}
          menuButtonEl={menuButtonRef}
          textButton="CLOSE"
          onClick={toggleMenu}
          lightHeader={lightHeader}
        />
      </NavBar>
    </HeaderWrapper>
  );
};

Header.propTypes = {};
