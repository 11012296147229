import { isDarkMode, isLightMode, isNotSpecified } from '../../utils/app';

export const colors = (() => {
  const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

  const darkTheme = {
    // Text
    eerie: '#141414',
    eerie900: '#2C2C2C',
    eerie800: '#434343',
    eerie500: '#8A8A8A',
    eerie200: '#5A5A5A',
    eerie100: '#E8E8E8',
    eerie50: '#EFEFEF',
    cultured: '#EEF0F2',
    // Secondary
    gradientOrange: 'linear-gradient(90deg, #FFB347 0%, #FFCC33 100%)',
    organge: '#FFB347',
    // Error
    ruby: '#DE9497',
    white: '#FFF',
    nightRider: '#2D2B2B',

    // button
    buttonFill: '#414141',
    buttonHover: '#2A2A2A',
    buttonFocused: '#414141',
    outlineButtonBg: '#121212',
    outlineButtonBor: '#868686',
    outlineButtonBgHover: '#121212',
    outlineButtonBorHover: '#5A5A5A',
    outlineButtonBgFocused: '#2D2D2D',

    //background
    backgroundLight: '#121212',
    backgroundMedium: '#2A2A2A',
    backgroundDark: '#121212',
    dropdownBackground: '#2A2A2A',

    // text color
    textNavColor: '#CACACA',
    textColor: '#CACACA',
    textDark200: '#616161',
    textDark100: '#757575',
    selectPlaceholder: '#CACACA',

    sharingListBg: '#2A2A2A',
    sharingListItemBgHover: '#595959',
    sharingListItemBgFocused: '#414141',
    sharingListBoxShadow: 'transparent',

    staffIntroColor: '#CACACA',
    staffBackground: '#CACACA',
    staffBor: '#868686',
    staffName: '#2D2D2D',

    polygonIcon: '#CACACA',
    footerColor: '#CACACA',

    menuColor: '#141414',
    productLink: '#868686',
    notFoundTitle: '#5A5A5A',
    notFoundDesc: '#CACACA',

    line: '#5A5A5A',
  };

  const lightTheme = {
    // Text
    eerie: '#141414',
    eerie900: '#2C2C2C',
    eerie800: '#434343',
    eerie500: '#8A8A8A',
    eerie200: '#D0D0D0',
    eerie100: '#E8E8E8',
    eerie50: '#EFEFEF',
    cultured: '#EEF0F2',
    // Secondary
    gradientOrange: 'linear-gradient(90deg, #FFB347 0%, #FFCC33 100%)',
    organge: '#FFB347',
    // Error
    ruby: '#9B1D20',
    white: '#FFF',
    nightRider: '#2D2B2B',

    // button
    buttonFill: '#141414',
    buttonHover: '#141414',
    buttonFocused: '#2C2C2C',
    outlineButtonBg: '#FFF',
    outlineButtonBor: '#D0D0D0',
    outlineButtonBgHover: '#FFF',
    outlineButtonBorHover: '#141414',
    outlineButtonBgFocused: '#E8E8E8',
    //background
    backgroundLight: '#ffffff',
    backgroundMedium: '#E8E8E8',
    backgroundDark: '#141414',
    dropdownBackground: '#fff',

    // text color
    textNavColor: '#EEF0F2',
    textColor: '#141414',
    textDark200: '#141414',
    textDark100: '#434343',
    selectPlaceholder: '#D0D0D0',

    sharingListBg: '#FFF',
    sharingListItemBgHover: '#EFEFEF',
    sharingListItemBgFocused: '#D0D0D0',
    sharingListBoxShadow: '0px 2px 20px 2px rgba(138, 138, 138, 0.24)',

    staffIntroColor: '#141414',
    staffBackground: '#FFF',
    staffBor: '#E8E8E8',
    staffName: '#141414',

    polygonIcon: '#2C2C2C',

    footerColor: '#EEF0F2',
    menuColor: '#FFF',

    productLink: '#434343',
    notFoundTitle: 'rgba(232, 232, 232, 0.3)',
    notFoundDesc: '#EFF0F2',
    line: '#E8E8E8',
  };

  return hasNoSupport || isDarkMode ? darkTheme : lightTheme;
})();
